	/*
  	Flaticon icon font: Flaticon
  	Creation date: 14/02/2020 11:09
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-qr-code:before { content: "\f100"; }
.flaticon-clean:before { content: "\f101"; }
.flaticon-analysis:before { content: "\f102"; }
.flaticon-left-quote:before { content: "\f103"; }
.flaticon-paper-plane:before { content: "\f104"; }
.flaticon-search-1:before { content: "\f105"; }
.flaticon-web-programming:before { content: "\f106"; }
.flaticon-medal:before { content: "\f107"; }
.flaticon-directory:before { content: "\f108"; }
.flaticon-value:before { content: "\f109"; }
.flaticon-appointment:before { content: "\f10a"; }
.flaticon-question:before { content: "\f10b"; }
.flaticon-candidate:before { content: "\f10c"; }
.flaticon-location:before { content: "\f10d"; }
.flaticon-suitcase:before { content: "\f10e"; }
.flaticon-salesman:before { content: "\f10f"; }
.flaticon-target:before { content: "\f110"; }
.flaticon-public-relations:before { content: "\f111"; }
.flaticon-shopping-bag:before { content: "\f112"; }
.flaticon-magnifying-glass:before { content: "\f113"; }
.flaticon-shopping-basket:before { content: "\f114"; }
.flaticon-call:before { content: "\f115"; }
.flaticon-location-1:before { content: "\f116"; }
.flaticon-email:before { content: "\f117"; }
.flaticon-address:before { content: "\f118"; }
.flaticon-burger:before { content: "\f119"; }
.flaticon-champagne-glass:before { content: "\f11a"; }
.flaticon-grocery:before { content: "\f11b"; }
.flaticon-mortarboard:before { content: "\f11c"; }
.flaticon-education:before { content: "\f11d"; }
.flaticon-employee:before { content: "\f11e"; }
.flaticon-search:before { content: "\f11f"; }
.flaticon-backup:before { content: "\f120"; }
.flaticon-filter:before { content: "\f121"; }
.flaticon-filter-1:before { content: "\f122"; }
.flaticon-refresh:before { content: "\f123"; }
.flaticon-refresh-1:before { content: "\f124"; }
.flaticon-french-fries:before { content: "\f125"; }
.flaticon-soda:before { content: "\f126"; }
.flaticon-bread-roll:before { content: "\f127"; }
.flaticon-fish:before { content: "\f128"; }
.flaticon-broccoli:before { content: "\f129"; }
.flaticon-meat:before { content: "\f12a"; }
.flaticon-supermarket:before { content: "\f12b"; }
.flaticon-cheese:before { content: "\f12c"; }
.flaticon-online-booking:before { content: "\f12d"; }
.flaticon-calories:before { content: "\f12e"; }
.flaticon-chili:before { content: "\f12f"; }
.flaticon-pizza-slice:before { content: "\f130"; }
.flaticon-pizza-and-cutted-slice:before { content: "\f131"; }
.flaticon-food-tray:before { content: "\f132"; }
.flaticon-baking:before { content: "\f133"; }
.flaticon-delivery-man:before { content: "\f134"; }
